/* General Styling for Dashboard */
.dashboard-container {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-family: 'Arial', sans-serif;
}

.dashboard-container h1, .dashboard-container h2 {
    text-align: center;
    margin: 10px 0;
    color: #333; /* Dark text for better readability */
}

/* Link customization section */
.link-customization-section {
    width: 90%;
    max-width: 600px;
    margin: 10px;
    background: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
}

/* Input and Button Styles */
.dashboard-container input,
.dashboard-container button {
    padding: 10px;
    margin: 10px 0;
    width: calc(100% - 22px);
    box-sizing: border-box;
}

.dashboard-container input {
    border: 1px solid #ccc;
    border-radius: 4px;
}

.dashboard-container button {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dashboard-container button:hover {
    background-color: #45a049;
}

/* Navigation Bar */
.top-bar {
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
}

.nav-link {
    text-decoration: none;
    color: #fff;
    padding: 8px 10px;
}

.nav-link:hover {
    background-color: #555;
    border-radius: 5px;
}

/* Editing Icons */
.edit-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-left: 20px;
}

/* Stats Container and Rows */
.stats-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;
}

.stats-row {
    background: white;
    padding: 20px;
    margin: 10px 0;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stats-row h2, .stats-row p {
    color: #555;
    margin: 0;
    flex: 1;
    text-align: center;
}

.download-app-button {
    display: block; /* Make the link a block element to take up the full width */
    width: fit-content; /* Adjust the width to fit the content */
    margin: 20px auto; /* Center horizontally and add vertical spacing */
    padding: 10px 20px; /* Padding to make it look more like a button */
    background-color: #007BFF; /* A pleasant blue background color */
    color: white; /* White text */
    text-align: center; /* Center the text inside the button */
    text-decoration: none; /* Remove underline from the link */
    border-radius: 5px; /* Rounded corners for the button */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.download-app-button:hover {
    background-color: #0056b3; /* Darken the background on hover */
    color: #fff; /* Just in case you want to change the text color on hover */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .stats-row {
        flex-direction: column;
    }

    .dashboard-container h1, .dashboard-container h2 {
        font-size: 1.2em;
    }

    .nav-link {
        font-size: 0.9em;
    }

    .top-bar, .nav-link {
        padding: 8px 15px;
    }

    .dashboard-container input,
    .dashboard-container button {
        width: 100%;
    }

    .dashboard-container .link-display,
    .dashboard-container .link-edit {
        width: 80%;
        flex-direction: column;
    }
}
