/* ContactUsPage.css */


.contact-us-page {
    max-width: 600px;
    margin: 40px auto;
    padding: 40px;
    background: #ffffff; /* Bright background */
    border-radius: 12px; /* More rounded corners */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Slightly deeper shadow for pop effect */
    font-family: 'Helvetica Neue', Arial, sans-serif; /* TikTok uses a similar modern font */
    color: #000; /* High contrast text color */
}

.contact-us-page h1 {
    color: #000;
    font-size: 28px; /* Larger heading for emphasis */
    margin-bottom: 24px;
}

.contact-us-page p {
    font-size: 16px;
    color: #333; /* Dark gray for readability */
    margin-bottom: 24px;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #000; /* High contrast for visibility */
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 15px; /* More padding for a better user experience */
    border-radius: 12px; /* Rounded edges */
    border: 2px solid #d3d3d3; /* Slightly thicker and lighter border */
    font-size: 16px; /* Larger font size */
    box-sizing: border-box;
}

.form-group textarea {
    resize: vertical;
    height: 120px; /* Optimized height for message input */
}

.submit-button {
    background-color: #69c9d0; /* Vibrant button color similar to TikTok's theme */
    color: white;
    border: none;
    padding: 15px 20px;
    cursor: pointer;
    border-radius: 12px; /* Rounded button edges */
    font-size: 18px; /* Bold and larger font for the button */
    font-weight: bold;
    transition: background-color 0.3s, transform 0.2s;
    margin-top: 10px; /* Ensure spacing above the button */
}

.submit-button:hover {
    background-color: #58b2be; /* A darker shade on hover for interaction feedback */
    transform: translateY(-2px); /* A subtle lift effect */
}

@media (max-width: 768px) {
    .contact-us-page {
        padding: 20px;
    }
}
