/* Existing Styles */
.features-section, .testimonials-section, .call-to-action {
    text-align: center;
    padding: 40px 20px;
}

.features {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.feature-item {
    width: 25%;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    border-radius: 8px;
    background: white;
}

.feature-item h3 {
    color: #333;
}

.testimonials-section {
    background: #f0f0f0;
}

.call-to-action {
    background: white;
    color: black;
    padding: 10px;
    margin-top: 20px;
}

.icon {
    max-width: 100px;
    height: auto;
    display: block;
    margin: 0 auto;
}

.button-style {
    background: white;
    color: black;
    padding: 10px;
    margin-top: 20px;
}

/* New Styles for Responsiveness */

