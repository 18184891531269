/* Style for the top bar container */
.top-bar {
    display: flex;
    justify-content: flex-start; /* Align items from the start to keep them in a row */
    padding: 10px 20px;
    background-color: transparent;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style for navigation links */
.nav-link {
    text-decoration: none;
    color: #fff;
    margin: 0 10px;
    font-size: 16px;
    transition: color 0.3s;
}

/* Hover effect for links */
.nav-link:hover {
    color: #0056b3;
}

/* Responsive design for smaller screens */
@media (max-width: 600px) {
    .top-bar {
        flex-direction: row; /* Keep items in a row on small screens */
        justify-content: space-around; /* Distribute space around items for even spacing */
    }
    .nav-link {
        font-size: 14px; /* Slightly smaller font size for mobile */
        margin: 0 5px; /* Reduce margin to accommodate smaller screen width */
    }
}
