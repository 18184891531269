/* Adjust the .faq-page container to ensure it integrates well with the global background */
.faq-page {
    margin: 20px auto;
    height: 150%;
    max-width: 800px;
    background: transparent; /* Ensure the global gradient shows through without interference */
}

/* Style for individual FAQ items */
.faq-item {
    display: flex; /* Activates flexbox layout */
    flex-direction: column; /* Organizes children (h2, p) vertically */
    align-items: flex-start; /* Aligns items to the start of the flex container */
    margin-bottom: 20px;
    background: rgba(249, 249, 249, 0.95); /* Slightly transparent to blend with the global background */
    border-left: 5px solid #4459F3; /* Maintains existing border styling */
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds depth */
}

.faq-item h2 {
    color: #4459F3; /* Updated accent color */
    margin-top: 0; /* Removes any top margin for h2 to align properly at the start */
    margin-bottom: 10px; /* Adds space below h2 for separation from paragraph */
}

.faq-item p {
    font-size: 16px;
    line-height: 1.6;
    color: #333; /* Keeps text color for readability */
}

/* Ensure the content does not sit too close to the fixed header */
.container {
    padding-top: 80px;
    height: 150%; /* Adjust based on your header's height */
}
