/* ProductUpload.css */
.form-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .form-container form {
    display: flex;
    flex-direction: column;
  }
  
  .form-container label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  .form-container input,
  .form-container textarea {
    margin-bottom: 15px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-container textarea {
    resize: vertical; /* Allows vertical resizing, generally more useful */
  }
  
  .form-container button {
    padding: 10px;
    background-color: #5c67f2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .form-container button:hover {
    background-color: #4a54e1;
  }
  
  .form-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .form-container {
      padding: 10px;
    }
  
    .form-container form {
      flex-direction: column;
    }
  }
  