.video-row {
    display: flex;
    justify-content: space-around; /* Adjusts space between videos */
    align-items: center; /* Aligns videos vertically */
  }
  
  /* Media query for screens smaller than 768px */
  @media (max-width: 768px) {
    .video-row {
      flex-direction: column; /* Stack videos vertically */
      align-items: stretch; /* Stretches videos to full width */
    }
  }