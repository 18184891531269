.settings-button, .logout-button {
    padding: 10px 30px;
    font-size: 1em;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: auto; /* Adjust width as needed, or keep it flexible */
}

.logout-button {
    background-color: #d9534f; /* Red for logout to emphasize the action */
}

.logout-button:hover {
    background-color: #c9302c; /* Darker shade on hover */
}


/* Dashboard.css */

.dashboard-container {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    /* background-color: #f9f9f9; */
    font-family: 'Arial', sans-serif;
}

.dashboard-container2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;
}

.dashboard-container h1 {
    color: #333;
    margin: 20px;
}

.dashboard-container h2 {
    color: #555; 
    margin: 10px 0;
}

.top-bar {
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
}

.nav-link {
    text-decoration: none;
    color: #fff;
    padding: 5px 10px;
}

.nav-link:hover {
    background-color: #555;
    border-radius: 5px;
}


.link-display {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    width: 100%;
}

.edit-icon {
    width: 24px;  
    height: 24px; 
    cursor: pointer;
    margin-left: 20px;
}


.dashboard-container div {
    background: white;
    padding: 20px;
    margin: 10px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
}

.dashboard-container input,
.dashboard-container button {
    padding: 10px;
    margin: 10px 0;
    width: calc(100% - 22px); 
    box-sizing: border-box; 
}

.dashboard-container input {
    border: 1px solid #ccc;
    border-radius: 4px;
}

.dashboard-container button {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dashboard-container button:hover {
    background-color: #45a049;
}

.dashboard-container .error {
    color: red;
    font-size: 0.9em;
}

@media (max-width: 480px) {
    .dashboard-container h1, .dashboard-container h2 {
        font-size: 1.2em;  
    }

    .top-bar, .nav-link {
        padding: 8px 15px;  
    }
}



/*======================================================*/

.stats-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;
    width: 100%; /* Full width of the parent element */
}

.stats-row {
    background: white;
    padding: 20px;
    margin: 10px 0;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stats-row h2 {
    color: #555;
    margin: 0;
    flex: 1 1 50%; /* Adjust flex-basis as needed */
    text-align: left;
}

.stats-row p {
    color: #333;
    margin: 0;
    flex: 1 0 50%; /* Adjust flex-basis as needed */
    text-align: right;
}

@media (max-width: 768px) {
    .stats-row {
        flex-direction: column;
        text-align: center;
    }

    .stats-row h2,
    .stats-row p {
        flex: none;
        width: 100%;
    }
}
