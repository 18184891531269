.video-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    align-items: center;
    padding: 10px 0;
    padding: max(10px, env(safe-area-inset-top)) 0 max(10px, env(safe-area-inset-bottom)) 0; /* Adjust padding to account for safe areas */
    width: 100%;  /* Ensure the container takes full width */
}


.video-cell {
    width: 100%;
    max-width: 500px; /* Adjust max-width for better control */
    margin: 75px 0;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
}

.video-wrapper {
    position: relative;
    width: 100%; /* Maintain full width of the video-cell */
    padding-top: 56.25%; /* Aspect ratio of 16:9; change if needed */
}

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 768px) {
    .video-cell {
        margin: 20px 0;  
    }
}
