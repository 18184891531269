.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(40, 44, 52, 0); /* Transparent when not scrolled */
    transition: background-color 0.3s ease-in-out;
    color: white;
    font-size: 16px;
    padding: 5px 10px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.header.scrolled {
    background-color: rgba(40, 44, 52, 0.85); /* Darker when scrolled */
}

.logo-container img {
    height: 25px;
}

.nav-toggle {
    display: none;
    cursor: pointer;
    flex-direction: column;
    gap: 4px;
}

.bar {
    width: 25px;
    height: 3px;
    background-color: white;
}

.navigation {
    display: flex;
    justify-content: center;
    gap: 15px;
    font-weight: bold;
}

.navigation a {
    text-decoration: none;
    color: white;
    font-size: 16px;
    padding: 8px 12px;
}

.navigation.expanded {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: rgba(40, 44, 52, 0.85);
    padding-top: 10px;
    padding-bottom: 10px;
    display: none;
}

.socials-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

@media (max-width: 768px) {

    .logo-container {
        margin-top: 15px
    }

    .nav-toggle {
        display: flex;
    }

    .navigation {
        display: none; 
    }

    .navigation.expanded {
        display: flex; 
    }

    .socials-container {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        justify-content: center;
        padding: 10px 0;
        background-color: rgba(40, 44, 52, 0.85);
        display: flex;
        gap: 50px; 
    }


}

/* Hover and focus styles for navigation links */
.navigation a:hover,
.navigation a:focus {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Responsive adjustments for padding and margins */
.header {
    padding: 5px 10px;
}

.logo-container {
    margin-left: 20px; /* Adjust based on your logo size */
}

/* Right section padding and margin adjustments */
.socials-container {
    padding-right: 20px;
}
