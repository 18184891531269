/* Base styles */
.affiliate-landing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-family: 'Arial', sans-serif;
}

.affiliate-landing div {
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    background: white;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    margin: 20px;
    width: 90%;
    max-width: 500px;
}

.affiliate-landing h1, .affiliate-landing p {
    margin: 0.5em 0;
}

.affiliate-landing button {
    padding: 15px 25px;
    margin: 10px;
    background-color: #5c67f2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.affiliate-landing button:hover {
    background-color: #4a54e1;
}

.affiliate-landing button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .affiliate-landing {
        padding: 10px;
    }

    .affiliate-landing div {
        width: 100%;
        max-width: none;
        margin: 10px;
        padding: 15px;
    }

    .affiliate-landing h1 {
        font-size: 1.2em; /* Adjusted font size for mobile screens */
    }

    .affiliate-landing p {
        font-size: 0.9em; /* Adjusted font size for mobile screens */
    }

    .affiliate-landing button {
        width: auto;
        font-size: 1em;
    }
}

