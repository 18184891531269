/* Container for the form to centralize and style all elements uniformly */
.form-container {
    max-width: 400px;
    margin: 40px auto;
    padding: 20px;  /* Reduced overall padding */
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

/* Uniform style for all form elements */
.form-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;  /* Reduced gap between form elements */
}

/* Styling specifically for labels to ensure they start from the edge */
.form-container label {
    display: block;
    text-align: left;
    margin-bottom: 5px;  /* Reduced bottom margin */
    font-weight: 600;
    color: #333;
    font-size: 0.9em;
    margin-left: 0;
}

/* Input and Select styling with consistent padding and border */
.form-container input,
.form-container select,
.form-container textarea {
    padding: 10px;  /* Reduced padding to decrease horizontal spacing */
    border: 2px solid #e3e3e3;
    border-radius: 8px;
    font-size: 0.95em;
    width: 100%;
    box-sizing: border-box;
}

/* Special styling for the platforms container to align with other inputs */
.platforms-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Button styling for interaction */
.form-container button {
    padding: 12px 18px;  /* Slightly reduced horizontal padding */
    background-color: #5c67f2;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.form-container button:hover {
    background-color: #4a54e1;
    transform: translateY(-2px);
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .form-container {
        margin: 20px;
        padding: 15px;  /* Slightly reduced padding for smaller screens */
    }

    .platforms-container {
        margin-left: 0;  /* Ensure alignment on mobile devices */
    }
}

/* Error message styling */
.error-message {
    color: #d9534f; /* Bootstrap danger color for consistency */
    font-size: 0.85em;
    margin-top: 5px;  /* Small margin to top align it closer to the element it refers to */
}
