.bottom-tab-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px; /* Increased padding for better spacing */
  background: transparent; /* Confirms the background is transparent */
  color: #333; /* Sets a default text color */
}

.bottom-tab-bar .left-section {
  font-size: 10px; /* Standard text size for readability */
  color: #000; /* Ensures text is black for visibility */
}

.bottom-tab-bar .right-section a {
  color: inherit; /* Inherits the text color from the parent */
  outline: none; /* Removes focus outline */
  text-decoration: none; /* Removes underline */
  padding: 0 10px; /* Adds horizontal padding around the icons */
  display: inline-flex;
  align-items: center;
  justify-content: center; /* Centers the content inside the link */
}

.bottom-tab-bar .right-section a:hover,
.bottom-tab-bar .right-section a:focus {
  color: #555; /* Subtle change in color on hover/focus */
  transform: scale(1.6); /* Slightly enlarges the icon on hover for interaction feedback */
  transition: color 0.2s ease-out, transform 0.2s ease-out; /* Smooth transition for color and transform */
}

.bottom-tab-bar .right-section a {
  font-size: 20px; /* Sets the icon size to be visually accessible */
  transform: scale(1.5); /* Scales the icons to make them prominent */
}
