
.hero-section {
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
  }
  
  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
  }
  
  .hero-text {
    z-index: 1;
    margin-top: -300px; 
  }
  
  .hero-text h1 {
    font-size: 3em; /* Increase font size */
    font-weight: bold; /* Make font bold */
  }
  
  .hero-text p {
    font-size: 1.5em; /* Larger than the normal text size */
    line-height: 1.4; /* Improve spacing between lines */
  }
  
  .hero-background-image-left, .hero-background-image-right {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    width: 30%;
  }
  
  .hero-background-image-left {
    left: 0;
  }
  
  .hero-background-image-right {
    right: 0;
  }
  
  .hero-background-image-left img, .hero-background-image-right img {
    width: 100%;
    height: auto;
  }

  .call-to-action {
    background-color: linear-gradient(#FEB849, #FE713A); /* Gradient effect */
    color: black;
    width: 150px;
    height: 50px;
    border-radius: 25px;
    padding: 30px 20px;
    border: none;
    cursor: pointer;
    font-size: 15px; 
    font-weight: 700;
}

  .button-section {
    background-color: linear-gradient(#FEB849, #FE713A); /* Gradient effect */
    width: 200px;
  }
  
@media (max-width: 768px) {
  .hero-content {
    flex-direction: column;
    text-align: center;
  }

  .hero-text {
    padding: 0;
    margin-top: 0; /* Reset the margin for smaller screens */
  }

  .hero-text h1 {
    font-size: 2em; /* Reduce font size on mobile */
  }

  .hero-text p {
    font-size: 1em; /* Adjust paragraph font size for mobile */
    line-height: 1.2; /* Adjust line height for better readability */
  }

  .hero-background-image-left {
    display: block; /* Ensure the left image is visible */
    position: absolute;
    top: 65%; /* Center vertically relative to the hero section */
    left: 50px; /* Maintain some margin from the left edge */
    width: 45%; /* Adjust width to be smaller on mobile */
    transform: translateY(-50%); /* Center the image vertically */
    z-index: 1;
  }

  .hero-background-image-right {
    display: block; /* Ensure the left image is visible */
    position: absolute;
    top: 65%; /* Center vertically relative to the hero section */
    right: -10px; /* Maintain some margin from the left edge */
    width: 70%; /* Adjust width to be smaller on mobile */
    transform: translateY(-50%); /* Center the image vertically */
  }
}
