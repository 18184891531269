.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px; /* Increased padding for better visual spacing */
  font-family: Arial, sans-serif;
  min-height: 100vh; /* Ensure it takes up at least the full viewport height */
  width: 100%; /* Full width to utilize space efficiently */
  /* background: linear-gradient(to bottom, #ffffff, #9BC9FD); */
  box-sizing: border-box; /* Ensures padding does not add to the width */
}

.info-form {
  display: flex;
  flex-direction: column;
  width: 90%; /* Adjusted for responsiveness */
  max-width: 400px; /* Maximum width to keep the form neatly contained */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  background: #ffffff; /* Solid background for form to ensure readability */
  padding: 20px; /* Padding inside the form for spacing */
  border-radius: 10px; /* Rounded corners for a modern look */
}

.input-field {
  padding: 12px; /* Slightly more padding for input field comfort */
  margin-bottom: 20px;
  border: 1px solid #ccd4da; /* Softer border color for less visual stress */
  border-radius: 5px;
  font-size: 16px; /* Slightly larger font size for readability */
}

.input-field:focus {
  border-color: #007bff; /* Highlight color when focused */
  box-shadow: 0 0 0 2px rgba(0,123,255,0.25); /* Adding a glow effect for focus */
  outline: none; /* Removing default focus outline */
}

.submit-button {
  padding: 12px 0; /* More vertical padding for a better tap target */
  background-color: #007bff;
  color: white;
  font-weight: bold; /* Making the text bold for emphasis */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover, .submit-button:focus {
  background-color: #0056b3; /* Darker shade for hover and focus states */
}

h2 {
  color: #333; /* Ensuring high contrast for readability */
  text-align: center; /* Center aligning the title for aesthetic balance */
  margin: 0 0 20px; /* Adjusting margin for spacing */
}
