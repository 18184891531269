.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 100%;
    max-width: 360px;
    margin: 30px auto; /* Reduced vertical margin for better spacing */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
}

form {
    width: 100%;
    box-sizing: border-box; /* Include padding and border in the width */
}

label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-size: 16px; /* Slightly larger font size for better readability */
    box-sizing: border-box; /* Include padding and border in the width */
}

input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 12px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box; /* Include padding and border in the width */
}

button {
    width: 90%;
    padding: 12px;
    margin-top: 10px;
    background-color: #5c67f2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    box-sizing: border-box; /* Include padding and border in the width */
}

button:hover {
    background-color: #4a54e1;
}

button[type="button"] {
    background-color: #f44336;
    margin-top: 20px; /* Extra margin for better spacing between buttons */
    box-sizing: border-box; /* Include padding and border in the width */
}

button[type="button"]:hover {
    background-color: #d32f2f;
}

.error {
    color: #ff4d4f;
    font-size: 14px;
    margin: 10px 0; /* Adjusted margin for error messages */
    box-sizing: border-box; /* Include padding and border in the width */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 480px) {
    .form-container {
        padding: 15px;
        margin-bottom: 10px; /* Reduced top and bottom margins */
    }

    input[type="email"],
    input[type="password"] {
        padding: 10px;
        font-size: 14px; /* Adjusted for smaller screens */
    }

    button {
        padding: 10px;
        font-size: 14px;
    }

    .error {
        font-size: 12px;
    }
}
