/* App.css */
.App {
  font-size: medium;
}

body {
  background: linear-gradient(#4459F3, #28D4FD);
  height: 150%;
  width: 100%;
}

.container {
  padding: 60px 20px 20px;
}

.App-header { /* Assuming this is for a different header, not TopTabBar */
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  width: 100%;
  position: fixed;
  top: 0;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Consider adjusting z-index if conflicts with TopTabBar */
  background-color: transparent; /* Ensure this doesn’t block TopTabBar styles */
}

.navigation a { /* Make sure this doesn't interfere with TopTabBar */
  text-decoration: none;
  color: white;
}

.nav-toggle {
  display: none;
}

@media (max-width: 768px) {
  .nav-toggle {
    display: block;
  }

  .navigation {
    flex-direction: column;
    padding-right: 30px; /* Check if this affects TopTabBar */
  }

  .navigation a {
    padding: 10px;
    text-align: center;
    width: 100%;
    border-bottom: 1px solid #444;
  }

  .navigation a:last-child {
    border-bottom: none;
  }
}
